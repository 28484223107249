import { resolveDynamicComponent } from 'vue'
import type { T3Appearance } from '@t3headless/nuxt-typo3'
import type { FileProperties } from '../components/T3CeRawlplugServices/T3CeRawlplugServices.types'
import type { T3CeAppearanceWhereToShow, T3CeBackgroundClass } from '../components/T3Frame/T3Frame.types'

interface ContainerBackground {
  publicUrl: string
  properties: FileProperties
}

export interface T3FrameProps extends T3Appearance {
  background?: T3CeBackgroundClass
  whereToShow?: T3CeAppearanceWhereToShow
  imageBackground?: ContainerBackground
  imageBackgroundMobile?: ContainerBackground
  watermark?: boolean
  blendMode?: boolean
  type?: string
  id: string
}

export function useT3Frame<TProps extends T3FrameProps>(
  props: TProps,
) {
  const BackgroundImage = () => {
    const desktopUrl = props.imageBackground?.publicUrl
    const mobileUrl = props.imageBackgroundMobile?.publicUrl

    const sources = [
      h('source', {
        srcset: mobileUrl,
        media: '(max-width: 640px)',
      }),
      h('source', {
        srcset: desktopUrl,
        media: '(min-width: 640px)',
      }),
    ]

    const img = h(resolveDynamicComponent('UiImage') as string, {
      src: desktopUrl || mobileUrl,
      class: 'object-cover size-full',
      width: 1300,
    })

    return h('picture', {
      class: 'absolute left-0 top-0 size-full object-cover',
      style: { zIndex: props.blendMode ? -3 : -1 },
    }, [...sources, img])
  }

  return {
    BackgroundImage,
  }
}
