<template>
  <!-- eslint-disable tailwindcss/no-custom-classname -->
  <div
    v-bind="$attrs"
    :id="props.id"
    class="t3-ce-frame relative"
    :class="[
      `frame-${props.frameClass}`,
      `layout-${props.layout}`,
      `space-before-${
        props.spaceBefore.length ? props.spaceBefore : 'default'
      }`,
      `space-after-${props.spaceAfter.length ? props.spaceAfter : 'default'}`,
      { 'overflow-hidden': isGradient || isBackgroundImage || props.type === 'background-container' },
      whereToShow,
    ]"
    :data-type="props.type"
  >
    <BackgroundImage
      v-if="isBackgroundImage"
    />

    <div
      class="absolute left-0 top-0 size-full"
      :style="{
        zIndex: -2,
      }"
      :class="[classBgr]"
    />

    <div
      v-if="props.watermark"
      class="absolute flex overflow-hidden md:-bottom-3 md:-left-10"
      :class="[{
        container: props.frameClass === 'full-width-with-container',
      }]"
      :style="{ zIndex: -1 }"
    >
      <UiIcon
        name="logo-short"
        :width="1056"
        class="relative inset-y-0 flex h-auto items-start xl:top-auto xl:h-auto xl:max-w-full xl:translate-x-0"
        :class="getTypo3WatermarkColor(props.background)"
        :style="{ aspectRatio: 1.77 }"
      />
    </div>

    <UiIcon
      v-else-if="isWatermarkVisible"
      name="logo-short"
      :width="1056"
      :height="578"
      class="absolute inset-y-0 left-0 h-96 -translate-x-1/4 text-white opacity-15 xl:top-auto xl:h-auto xl:max-w-full xl:translate-x-0"
      :style="{ zIndex: -1 }"
    />

    <div
      v-if="props.frameClass === 'full-width-with-container'"
      class="container"
    >
      <slot />
    </div>

    <slot v-else />
  </div>
</template>

<script lang="ts" setup>
import { getTypo3BackgroundColor, getTypo3WatermarkColor } from '../../helpers/typo3-colors'
import { type T3FrameProps, useT3Frame } from '../../composables/useT3Frame'
import { T3FrameAppearanceKey } from './T3Frame.types'

const props = withDefaults(defineProps<T3FrameProps>(), {
  background: '',
  imageBackground: undefined,
  imageBackgroundMobile: undefined,
  watermark: undefined,
  whereToShow: '',
  type: '',
})

const classBgr = computed(() => {
  const classes = [getTypo3BackgroundColor(props.background)]

  if (props.blendMode) {
    classes.push('mix-blend-multiply')
  }

  return classes
})

provide(T3FrameAppearanceKey, {
  background: props.background
    ? getTypo3BackgroundColor(props.background)
    : inject(T3FrameAppearanceKey, undefined)?.background,
})

const isGradient = computed(() => props.background.includes('gradient'))
const isBackgroundImage = computed(() => props.imageBackground?.publicUrl || props.imageBackgroundMobile?.publicUrl)
const isWatermarkVisible = computed(() => {
  if (props.watermark === undefined) { return isGradient.value }
  return Boolean(props.watermark)
})
const { BackgroundImage } = useT3Frame(props)
</script>

<style lang="postcss" scoped>
.t3-ce-frame {
  &[data-type='rawlplug_accountperson'] {
    &:not(:has([data-el='t3-ce-rawlplug-accountperson'])) {
      @apply hidden;
    }
  }

  &:has([data-el='ui-slider-hero']) {
    @media not all and screen(lg) {
      @apply !pt-0;
    }
  }
}
</style>
